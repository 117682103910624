<template>
  <div id="app" class="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
html, body {
  background-color: #f0f4f8; /* Beige background */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
}

</style>
