
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

const app = createApp(App);

// Disable zooming
document.addEventListener('wheel', function(event) {
  if (event.ctrlKey) {
    event.preventDefault();
  }
}, { passive: false });

document.addEventListener('touchmove', function(event) {
  if (event.scale !== 1) {
    event.preventDefault();
  }
}, { passive: false });

app.use(router).mount('#app');
