<template>
  <div class="card">
    <div class="chart-header">
      <div></div>
      <button
        class="btn-download"
        @click="downloadChart"
        title="Download Chart as Image"
      >
        <span class="download-icon">⬇️</span> Download
      </button>
    </div>
    <div class="chart-container">
      <LineChart ref="sensorChart" :data="chartData" :options="chartOptions" />
    </div>
    <!-- Modal for mobile preview -->
    <div v-if="showImageModal" class="image-modal" @click="closeOnBackdropClick">
      <div class="image-modal-content" @click.stop>
        <img :src="chartImage" alt="Chart Image Preview" />
        <p>Long click on the image to save it</p>
        <button @click="closeModal">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Line as LineChart } from "vue-chartjs";
import "chartjs-adapter-date-fns";

// Register all required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

// Create a custom plugin to add a white background before drawing the chart
const whiteBackgroundPlugin = {
  id: "whiteBackground",
  beforeDraw(chart) {
    const ctx = chart.ctx;
    ctx.save();
    ctx.fillStyle = "white"; // Set background color to white
    ctx.fillRect(0, 0, chart.width, chart.height); // Fill the entire chart area with white
    ctx.restore();
  },
};

export default {
  name: "SensorChart",
  components: {
    LineChart,
  },
  data() {
    return {
      showImageModal: false, // Control modal visibility
      chartImage: null, // Store chart image for modal
    };
  },
  watch: {
    showImageModal(newValue) {
      if (newValue) {
        document.body.style.overflow = 'hidden'; // Prevent background scrolling
      } else {
        document.body.style.overflow = ''; // Re-enable background scrolling
      }
    }
  },
  props: {
    sensorSN: {
      type: String,
      required: true,
    },
    sensorData: {
      type: Array,
      required: true,
    },
    xAxisType: {
      type: String,
      required: true,
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.sensorData.map((dataPoint) => dataPoint[this.xAxisType]),
        datasets: [
          {
            label: "",
            backgroundColor: "black",
            borderColor: "black",
            data: this.sensorData.map((dataPoint) => dataPoint.humi_value),
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false, // Hide legend
          },
          title: {
            display: true,
            text: `Sensor Nª ${this.sensorSN}`,
            font: {
              family: "Arial", // Set font to Arial
              size: 16, // Set font size (optional)
            },
          },
        },
        scales: {
          x: {
            type: this.xAxisType === "timestamp" ? "time" : "linear",
            time: {
              unit: "day", // Set the smallest unit to 'day'
              tooltipFormat: "PP", // Show only the date in the tooltip
              displayFormats: {
                day: "dd/MM/yy", // Display format for the date
              },
            },
            title: {
              display: true,
              text: "",
            },
          },
          y: {
            title: {
              display: true,
              text: "Moisture Value",
            },
          },
        },
      };
    },
  },
  methods: {
    downloadChart() {
      this.$nextTick(() => {
        const chart = this.$refs.sensorChart.chart;
        if (chart) {
          const imageUrl = chart.toBase64Image();
          if (window.innerWidth <= 768) {
            // For mobile devices, open the image in a new tab
            this.chartImage = imageUrl;
            this.showImageModal = true;
          } else {
            // For desktop, trigger the download
            const link = document.createElement("a");
            link.href = imageUrl;
            link.download = `${this.sensorSN}-chart.png`;
            link.click();
          }
        } else {
          console.error("Chart instance not found");
        }
      });
    },
    closeModal() {
      this.showImageModal = false;
    },
    closeOnBackdropClick(event) {
      if (event.target.classList.contains('image-modal')) {
        this.closeModal();
      }
    },
  },
  mounted() {
    // Register the white background plugin globally when the component is mounted
    ChartJS.register(whiteBackgroundPlugin);
  },
};
</script>

<style scoped>
.chart-container canvas {
  background-color: white;
}

.card {
  background-color: #fff;
  padding: 20px 20px 0 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin-bottom: 20px;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart-container {
  position: relative;
  height: 400px;
}

.btn-download {
  background-color: #344964;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.btn-download:hover {
  background-color: #2b3a4e;
}

.download-icon {
  margin-right: 8px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .chart-container {
    position: relative;
    height: 350px;
  }
}

@media (max-width: 480px) {
  .chart-container {
    position: relative;
    height: 300px;
  }
}

/* Modal for previewing the image on mobile */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Darken background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other elements */
}

.image-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  margin: 0 10px;
  text-align: center;
}

.image-modal-content img {
  max-width: 100%;
  /* height: auto; */
}

.image-modal-content p {
  font-family: Arial, sans-serif;
  color: #898989;
  font-size: 16px;
  margin-top: 0px;
}

.image-modal-content button {
  padding: 10px 15px;
  background-color: #344964;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.image-modal-content button:hover {
  background-color: #2b3a4e;
}
</style>
