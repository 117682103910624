<template>
  <div class="login-container">
    <div class="header">
      <img :src="logoSrc" alt="Logo" class="logo-image" />
    </div>
    <div class="login-box">
      <h2>Please Enter Password</h2>
      <input type="password" v-model="password" placeholder="Enter password" class="password-input"/>
      <button @click="login" class="login-button">Login</button>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: '',
      errorMessage: '',
      logoSrc: process.env.VUE_APP_HUMI_IMAGE
    };
  },
  methods: {
    login() {
      const correctPassword = process.env.VUE_APP_PASSWORD;
      if (this.password === correctPassword) {
        localStorage.setItem('authenticated', 'true');
        this.$router.push('/data');
      } else {
        this.errorMessage = 'Incorrect password. Please try again.';
      }
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f4f8;
  width: 100%;
}

.header {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 55px;
}

.logo-image {
  max-width: 100%;
  height: auto;
  width: 80%; /* Adjust this to your needs */
  max-width: 400px;
}

.login-box {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 850px;
  width: 95%;
  box-sizing: border-box;
}

h2 {
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.password-input {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
}

.login-button {
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.login-button:hover {
  background-color: #0056b3;
}

.error-message {
  margin-top: 20px;
  color: red;
  font-family: Arial, sans-serif;
}
</style>
