<template>
  <div class="data-view-container">
    <!-- Logo Section -->
    <div class="header">
      <img :src="logoSrc" alt="Logo" class="logo-image" />
    </div>

    <!-- Main Data View Box -->
    <div class="data-view-box">
      <h2>Data View</h2>

      <!-- Input and Search Section -->
      <div v-if="!data">
        <!-- Buttons to Select Search Type -->
        <div class="two-buttons">
          <button
            :class="{ selected: searchType === 'MESN' }"
            @click="searchType = 'MESN'"
            class="two-button left"
          >
            Search by MursecECO
          </button>
          <button
            :class="{ selected: searchType === 'HSSN' }"
            @click="searchType = 'HSSN'"
            class="two-button right"
          >
            Search by HumiScout
          </button>
        </div>

        <!-- Serial Number Input Field -->
        <input
          v-model="serialNumber"
          :placeholder="`Enter ${
            searchType === 'MESN'
              ? 'MursecECO Serial Number'
              : 'HumiScout Serial Number'
          }`"
          class="serial-input"
        />

        <!-- Button to Fetch Data -->
        <button @click="fetchData" class="fetch-button">Search Data</button>

        <!-- Error Message -->
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>

      <!-- Data and Chart Section -->
      <div v-if="data">
        <!-- Button to Reset Data -->
        <button @click="resetData" class="fetch-button another">
          Search another
        </button>

        <!-- Buttons to Choose X-Axis Type -->
        <div class="two-buttons">
          <button
            :class="{ selected: xAxisType === 'timestamp' }"
            @click="xAxisType = 'timestamp'"
            class="two-button left"
          >
            Use Timestamp
          </button>
          <button
            :class="{ selected: xAxisType === 'timeworked' }"
            @click="xAxisType = 'timeworked'"
            class="two-button right"
          >
            Use Minutes Worked
          </button>
        </div>

        <!-- Charts Display Section -->
        <div class="charts-container">
          <div class="chart-container">
            <SensorChart
              v-for="(sensorSN, index) in Object.keys(data)"
              :key="index"
              :sensor-data="data[sensorSN]"
              :sensorSN="sensorSN"
              :x-axis-type="xAxisType"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SensorChart from "./SensorChart.vue";

export default {
  name: "SerialDataView",
  components: {
    SensorChart,
  },
  data() {
    return {
      searchType: "MESN", // Default search type
      serialNumber: "", // Serial number input by the user
      data: null, // Data fetched from the server
      xAxisType: "timestamp", // Default x-axis type for charts
      logoSrc: process.env.VUE_APP_HUMI_IMAGE, // Logo source from environment variable
      errorMessage: "", // Error message to display if the serial number is incorrect
    };
  },
  methods: {
    // Fetch data based on the selected search type and serial number
    fetchData() {
      const baseUrl = process.env.VUE_APP_ENDPOINT;
      const apiKey = process.env.VUE_APP_APIKEY;
      const queryParam = this.searchType === "MESN" ? "MESN" : "HSSN";
      const url = `${baseUrl}?${queryParam}=${this.serialNumber}&api_key=${apiKey}`;

      axios
        .get(url)
        .then((response) => {
          if (!Object.keys(response.data).length) {
            this.errorMessage = "Incorrect number. No log found.";
          } else {
            this.data = response.data;
            this.errorMessage = "";
          }
        })
        .catch(() => {
          this.errorMessage = "Incorrect number. No log found.";
        });
    },
    // Reset the data to allow for a new search
    resetData() {
      this.data = null;
    },
  },
};
</script>


<style scoped>
.header {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 55px;
}

.logo-image {
  height: auto;
  width: 80%; /* Adjust this to your needs */
  max-width: 400px;
}

.error-message {
  margin-top: 20px;
  color: red;
  font-family: Arial, sans-serif;
}

.data-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f4f8;
  width: 100%;
}

.data-view-box {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  /* max-width: 1050px; */
  width: 670px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.two-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.two-button {
  padding: 10px 20px;
  background-color: white;
  border: 1px solid #ccc;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  min-height: 40px;
}

.two-button.right {
  border-radius: 0 4px 4px 0;
}

.two-button.left {
  border-radius: 4px 0 0 4px;
}

.two-button.selected {
  background-color: #dbdbdb;
  color: rgb(0, 0, 0);
}

.serial-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

.fetch-button {
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.another {
  background-color: #aad3ff;
}

.fetch-button:hover {
  background-color: #0056b3;
}

.subtitle {
  font-size: 16px;
  color: #333;
}

.charts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 610px;
}

.chart-container {
  width: 100%;
}

@media (max-width: 768px) {
  .data-view-box {
    padding: 1rem;
  }

  .serial-input,
  .fetch-button {
    font-size: 16px;
    max-width: 100%; /* Ensure the input and button take the full width */
  }

  .data-view-box {
    width: 95%;
  }

  .subtitle {
    font-size: 16px;
  }

  .charts-container {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .data-view-box {
    padding: 1rem;
  }

  .serial-input,
  .fetch-button {
    font-size: 16px;
    max-width: 100%; /* Ensure the input and button take the full width */
  }

  .subtitle {
    font-size: 16px;
  }

  .search-button,
  .x-axis-button {
    font-size: 16px;
    padding: 0.5rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
