
import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '@/components/UserLogin.vue';
import SerialDataView from '@/components/SerialDataView.vue';

const isAuthenticated = () => {
  // Simple authentication check: replace this with real authentication logic
  return localStorage.getItem('authenticated') === 'true';
};

const routes = [
  {
    path: '/',
    name: 'UserLogin',
    component: UserLogin
  },
  {
    path: '/data',
    name: 'SerialDataView',
    component: SerialDataView,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next('/');
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
